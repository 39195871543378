import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import QuickSummary from "../components/common/QuickSummary";
import ContactUsForm from "../components/main/ContactUsForm";
import Footer from "../layouts/Footer";
import { QuickLandingData } from "../constants";
import QuickSummaryJetblue from "../components/common/QuickSummaryJetblue";
import QuickSummaryAlaska from "../components/common/QuickSummaryAlaska";
import QuickSummarySouthwest from "../components/common/QuickSummarySouthwest";
import QuickSummaryCanada from "../components/common/QuickSummaryCanada";
import QuickSummaryDelta from "../components/common/QuickSummaryDelta";
import QuickSummaryIberia from "../components/common/QuickSummaryIberia";
import QuickSummarySunCountry from "../components/common/QuickSummarySunCountry";
import QuickSummaryAeroMexico from "../components/common/QuickSummaryAeroMexico";
import QuickSummaryFrontier from "../components/common/QuickSummaryFrontier";

const getHeadTag = (id) =>
({
  france: "Air France Ticket Booking",
  british: "British Airways Ticket Booking",
  lufthansa: "Lufthansa Ticket Booking",
  virgin: "Virgin Atlantic Ticket Booking",
  alaska: "Alaska Airlines Ticket Booking",
  southwest: "Southwest Airlines Ticket Booking",
  jetblue: "Jetblue Airways Ticket Booking",
  american: "American Airlines Ticket Booking",
  delta: "Delta Airlines Ticket Booking",
  iberia: "Iberia Airlines Ticket Booking",
  united: "United Airlines Ticket Booking",
  canada: "Air Canada Ticket Booking",
  sunCountry: "Sun Country Airlines Ticket Booking",
  aeroMexico: "Aero Mexico Airlines Ticket Booking",
  frontier: "Frontier Airlines Ticket Booking",
  evaAir: "EVA Air Ticket Booking",
  emirates: "Emirates Airlines Ticket Booking",
  tapAirPortugal: "TAP Air Portugal Ticket Booking",
  swissAir: "Swiss Air Ticket Booking",
  qatar: "Qatar Airways Ticket Booking"
}[id] || "");

const getKeywords = (id) =>
({
  france: "air france book a flight, air france reservations, air france booking, air france flight reservations, air france flight reservation, air france ticket reservations, air france booking reservations, book an air france flight, air france reservations book a flight",
  british: "British Airways book a flight, British Airways reservations, British Airways booking, British Airways flight reservations, British Airways flight reservation, British Airways ticket reservations, British Airways booking reservations, book an British Airways flight, British Airways reservations book a flight",
  lufthansa: "Lufthansa book a flight, Lufthansa reservations, Lufthansa booking, Lufthansa flight reservations, Lufthansa flight reservation, Lufthansa ticket reservations, Lufthansa booking reservations, book an Lufthansa flight, Lufthansa reservations book a flight",
  virgin: "Virgin Atlantic book a flight, Virgin Atlantic reservations, Virgin Atlantic booking, Virgin Atlantic flight reservations, Virgin Atlantic flight reservation, Virgin Atlantic ticket reservations, Virgin Atlantic booking reservations, book an Virgin Atlantic flight, Virgin Atlantic reservations book a flight",
  alaska: "Alaska Airlines book a flight, Alaska Airlines reservations, Alaska Airlines booking, Alaska Airlines flight reservations, Alaska Airlines flight reservation, Alaska Airlines ticket reservations, Alaska Airlines booking reservations, book an Alaska Airlines flight, Alaska Airlines reservations book a flight",
  southwest: "southwest airlines book a flight, southwest airlines reservations, southwest airlines booking, southwest airlines flight reservations, southwest flight reservation, southwest ticket reservations, southwest airlines booking reservations, book a southwest airlines flight, southwest airlines reservations book a flight",
  jetblue: "Jetblue Airways book a flight, Jetblue Airways reservations, Jetblue Airways booking, Jetblue Airways flight reservations, Jetblue Airways flight reservation, Jetblue Airways ticket reservations, Jetblue Airways booking reservations, book an Jetblue Airways flight, Jetblue Airways reservations book a flight",
  american: "American Airlines book a flight, American Airlines reservations, American Airlines booking, American Airlines flight reservations, American Airlines flight reservation, American Airlines ticket reservations, American Airlines booking reservations, book an American Airlines flight, American Airlines reservations book a flight",
  delta: "Delta Airlines book a flight, Delta Airlines reservations, Delta Airlines booking, Delta Airlines flight reservations, Delta Airlines flight reservation, Delta Airlines ticket reservations, Delta Airlines booking reservations, book an Delta Airlines flight, Delta Airlines reservations book a flight",
  iberia: "Iberia Airlines book a flight, Iberia Airlines reservations, Iberia Airlines booking, Iberia Airlines flight reservations, Iberia Airlines flight reservation, Iberia Airlines ticket reservations, Iberia Airlines booking reservations, book an Iberia Airlines flight, Iberia Airlines reservations book a flight",
  united: "United Airlines book a flight, United Airlines reservations, United Airlines booking, United Airlines flight reservations, United Airlines flight reservation, United Airlines ticket reservations, United Airlines booking reservations, book an United Airlines flight, United Airlines reservations book a flight",
  canada: "Air Canada book a flight, Air Canada reservations, Air Canada booking, Air Canada flight reservations, Air Canada flight reservation, Air Canada ticket reservations, Air Canada booking reservations, book an Air Canada flight, Air Canada reservations book a flight",
  sunCountry: "Sun Country Airlines book a flight, Sun Country Airlines reservations, Sun Country Airlines booking, Sun Country Airlines flight reservations, Sun Country Airlines flight reservation, Sun Country Airlines ticket reservations, Sun Country Airlines booking reservations, book an Sun Country Airlines flight, Sun Country Airlines reservations book a flight",
  aeroMexico: "Aero Mexico Airlines book a flight, Aero Mexico Airlines reservations, Aero Mexico Airlines booking, Aero Mexico Airlines flight reservations, Aero Mexico Airlines flight reservation, Aero Mexico Airlines ticket reservations, Aero Mexico Airlines booking reservations, book an Aero Mexico Airlines flight, Aero Mexico Airlines reservations book a flight",
  frontier: "Frontier Airlines book a flight, Frontier Airlines reservations, Frontier Airlines booking, Frontier Airlines flight reservations, Frontier Airlines flight reservation, Frontier Airlines ticket reservations, Frontier Airlines booking reservations, book an Frontier Airlines flight, Frontier Airlines reservations book a flight",
  evaAir: "EVA Air book a flight, EVA Air reservations, EVA Air booking, EVA Air flight reservations, EVA Air flight reservation, EVA Air ticket reservations, EVA Air booking reservations, book an EVA Air flight, EVA Air reservations book a flight",
  emirates: "Emirates Airlines book a flight, Emirates Airlines reservations, Emirates Airlines booking, Emirates Airlines flight reservations, Emirates Airlines flight reservation, Emirates Airlines ticket reservations, Emirates Airlines booking reservations, book an Emirates Airlines flight, Emirates Airlines reservations book a flight",
  tapAirPortugal: "TAP Air Portugal book a flight, TAP Air Portugal reservations, TAP Air Portugal booking, TAP Air Portugal flight reservations, TAP Air Portugal flight reservation, TAP Air Portugal ticket reservations, TAP Air Portugal booking reservations, book an TAP Air Portugal flight, TAP Air Portugal reservations book a flight",
  swissAir: "Swiss Air book a flight, Swiss Air reservations, Swiss Air booking, Swiss Air flight reservations, Swiss Air flight reservation, Swiss Air ticket reservations, Swiss Air booking reservations, book an Swiss Air flight, Swiss Air reservations book a flight",
  qatar: "Qatar Airways book a flight, Qatar Airways reservations, Qatar Airways booking, Qatar Airways flight reservations, Qatar Airways flight reservation, Qatar Airways ticket reservations, Qatar Airways booking reservations, book an Qatar Airways flight, Qatar Airways reservations book a flight",
}[id] || "");


const getDescription = (id) =>
({
  france: "Discover seamless travel with Air France - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Air France today.",
  british: "Discover seamless travel with British Airways - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with British Airways today.",
  lufthansa: "Discover seamless travel with Lufthansa - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Lufthansa today.",
  virgin: "Discover seamless travel with Virgin Atlantic - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Virgin Atlantic today.",
  alaska: "Discover seamless travel with Alaska Airlines - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Alaska Airlines today.",
  southwest: "Discover seamless travel with Southwest Airlines - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Southwest Airlines today.",
  jetblue: "Discover seamless travel with Jetblue Airways - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Jetblue Airways today.",
  american: "Discover seamless travel with American Airlines - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with American Airlines today.",
  delta: "Discover seamless travel with Delta Airlines - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Delta Airlines today.",
  iberia: "Discover seamless travel with Iberia Airlines - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Iberia Airlines today.",
  united: "Discover seamless travel with United Airlines - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with United Airlines today.",
  canada: "Discover seamless travel with Air Canada - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Air Canada today.",
  sunCountry: "Discover seamless travel with Sun Country Airlines - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Sun Country Airlines today.",
  aeroMexico: "Discover seamless travel with Aero Mexico Airlines - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Aero Mexico Airlines today.",
  frontier: "Discover seamless travel with Frontier Airlines - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Frontier Airlines today.",
  evaAir: "Discover seamless travel with EVA Air - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with EVA Air today.",
  emirates: "Discover seamless travel with Emirates Airlines - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Emirates Airlines today.",
  tapAirPortugal: "Discover seamless travel with TAP Air Portugal - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with TAP Air Portugal today.",
  swissAir: "Discover seamless travel with Swiss Air - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Swiss Air today.",
  qatar: "Discover seamless travel with Qatar Airways - book a flight ticket effortlessly online. Explore easy reservations, exclusive booking options, and secure your next journey with Qatar Airways today."
}[id] || "");

const getTitle = (id) =>
({
  france: "Air France Reservations | Book Air France Flight Ticket - Vertical Fleet",
  british: "British Airways Reservations | Book British Airways Flight Ticket - Vertical Fleet",
  lufthansa: "Lufthansa Reservations | Book Lufthansa Flight Ticket - Vertical Fleet",
  virgin: "Virgin Atlantic Reservations | Book Virgin Atlantic Flight Ticket - Vertical Fleet",
  alaska: "Alaska Airlines Reservations | Book Alaska Airlines Flight Ticket - Vertical Fleet",
  southwest: "Southwest Airlines Reservations | Book SWA Flight Ticket - Vertical Fleet",
  jetblue: "Jetblue Airways Reservations | Book Jetblue Airways Flight Ticket - Vertical Fleet",
  american: "American Airlines Reservations | Book American Airlines Flight Ticket - Vertical Fleet",
  delta: "Delta Airlines Reservations | Book Delta Airlines Flight Ticket - Vertical Fleet",
  iberia: "Iberia Airlines Reservations | Book Iberia Airlines Flight Ticket - Vertical Fleet",
  united: "United Airlines Reservations | Book United Airlines Flight Ticket - Vertical Fleet",
  canada: "Air Canada Reservations | Book Air Canada Flight Ticket - Vertical Fleet",
  sunCountry: "Sun Country Airlines Reservations | Book Sun Country Airlines Flight Ticket - Vertical Fleet",
  aeroMexico: "Aero Mexico Airlines Reservations | Book Aero Mexico Airlines Flight Ticket - Vertical Fleet",
  frontier: "Frontier Airlines Reservations | Book Frontier Airlines Flight Ticket - Vertical Fleet",
  evaAir: "EVA Air Reservations | Book EVA Air Flight Ticket - Vertical Fleet",
  emirates: "Emirates Airlines Reservations | Book Emirates Airlines Flight Ticket - Vertical Fleet",
  tapAirPortugal: "TAP Air Portugal Reservations | Book TAP Air Portugal Flight Ticket - Vertical Fleet",
  swissAir: "Swiss Air Reservations | Book Swiss Air Flight Ticket - Vertical Fleet",
  qatar: "Qatar Airways Reservations | Book Qatar Airways Flight Ticket - Vertical Fleet"
}[id] || "");

const QuickLanding = () => {
  const { id } = useParams();

  const isMobile = window.innerWidth < 600;

  const [show, setShow] = useState(isMobile);

  const handleShowLayer = () => {
    setShow(false);
    localStorage.setItem("isVisited", "true");
  };

  const isVisited = localStorage.getItem("isVisited");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{getTitle(id)}</title>
        <meta name="description" content={getDescription(id)} />
        <meta name="keywords" content={getKeywords(id)} />
        <link rel="canonical" href={`https://verticalfleet.com/landing/${id}`} />
        <meta property="og:title" content={getTitle(id)} />
        <meta property="og:description" content={getDescription(id)} />
      </Helmet>
      {/* {!show || isVisited ? ( */}
      <>
        {!(id && QuickLandingData[id]) ? (
          <div className="text-center mt-[200px] text-[30px] font-bold">
            Page Not Found
          </div>
        ) : (
          <>
            <div className="bg-[#F5F5F5] md:pt-[150px] pt-[100px] pb-[60px] xl:px-0 px-[10px]">
              <h1 itemProp="name" className="text-[#F5F5F5]">{getHeadTag(id)}</h1>
              <div className="uppercase text-center font-bold md:text-[80px] leading-[100px] text-[44px] text-[#10091D]/[.1]">
                ask us anything
              </div>
              <ContactUsForm />
            </div>
            {id === "jetblue" ? (
              <QuickSummaryJetblue data={QuickLandingData[id]} />
            ) : id === "alaska" ? (
              <QuickSummaryAlaska data={QuickLandingData[id]} />
            ) : id === "southwest" ? (
              <QuickSummarySouthwest data={QuickLandingData[id]} />
            ) : id === "canada" ? (
              <QuickSummaryCanada data={QuickLandingData[id]} />
            ) : id === "delta" ? (
              <QuickSummaryDelta data={QuickLandingData[id]} />
            ) : id === "iberia" ? (
              <QuickSummaryIberia data={QuickLandingData[id]} />
            ) : id === "sunCountry" ? (
              <QuickSummarySunCountry data={QuickLandingData[id]} />
            ) : id === "aeroMexico" ? (
              <QuickSummaryAeroMexico data={QuickLandingData[id]} />
            ) : id === "frontier" ? (
              <QuickSummaryFrontier data={QuickLandingData[id]} />
            ) : (
              <QuickSummary data={QuickLandingData[id]} />
            )}
            <Footer />
          </>
        )}
      </>
      {/* ) : (
        <ContactPopup onShowLayer={handleShowLayer} />
      )} */}
    </>
  );
};

export default QuickLanding;
