import React from "react";
import PhoneLogo from "../../assets/img/main/phone_yellow.png";

const BadgePhone: React.FC = (): JSX.Element => {
  return (
    <div className="w-full fixed bottom-0 z-50">
      <div className="flex justify-center py-4 bg-[#10091D] shadow-[0_-5px_20px_rgba(0,0,0,0.1)] gap-8">
        <a href="tel:+1-888-832-8777">
          <img
            src={PhoneLogo}
            alt="phone"
            className="p-3 border-[3px] border-[#F3E351] rounded-full "
          />
        </a>
        <div className="flex flex-col font-medium gap-2">
          <p className="text-[#F3E351] text-[12px] leading-[16px] border-b border-b-[#F3E351] flex justify-end">
            CALL FOR UNPUBLISHED DEALS!
          </p>
          <a href="tel:+1-888-832-8777">
            <div className="text-[28px] leading-[28px] text-white cursor-pointer flex">
              <div>
                <span className="pr-1 text-white/50">+</span>
              </div>
              <div>
                <span>1</span>
                <span className="pr-1 text-white/50">-</span>
                <span>888</span>
              </div>
              <div>
                <span className="pr-1 text-white/50">-</span>
                <span>832</span>
              </div>
              <div>
                <span className="pr-1 text-white/50">-</span>
                <span>8777</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BadgePhone;
