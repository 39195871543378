export const reportItemsArray = [
  {
    title: "24/7 customer support",
    description: "Success in each case",
  },
  {
    title: "10+ years experience",
    description: "In the Travel Industry",
  },
  {
    title: "unpublished inventory",
    description: "From all over the world",
  },
  {
    title: "best rates and experience",
    description: "At your service",
  },
];

export const forePhoneNumbers = [
  "93",
  "355",
  "213",
  "684",
  "376",
  "244",
  "264",
  "672",
  "268",
  "54",
  "374",
  "297",
  "61",
  "43",
  "994",
  "242",
  "973",
  "880",
  "246",
  "375",
  "32",
  "501",
  "229",
  "441",
  "975",
  "591",
  "387",
  "267",
  "55",
  "673",
  "359",
  "226",
  "257",
  "855",
  "237",
  "1",
  "238",
  "345",
  "236",
  "235",
  "56",
  "86",
  "53",
  "61",
  "57",
  "269",
  "243",
  "242",
  "682",
  "506",
  "225",
  "385",
  "53",
  "357",
  "420",
  "45",
  "253",
  "767",
  "809",
  "670",
  "593",
  "20",
  "503",
  "240",
  "291",
  "372",
  "251",
  "500",
  "298",
  "679",
  "358",
  "33",
  "594",
  "689",
  "241",
  "220",
  "995",
  "49",
  "233",
  "350",
  "30",
  "299",
  "473",
  "590",
  "671",
  "502",
  "224",
  "245",
  "592",
  "509",
  "504",
  "852",
  "36",
  "354",
  "91",
  "62",
  "98",
  "964",
  "353",
  "972",
  "39",
  "876",
  "81",
  "962",
  "7",
  "254",
  "686",
  "850",
  "82",
  "965",
  "996",
  "856",
  "371",
  "961",
  "266",
  "231",
  "218",
  "423",
  "370",
  "352",
  "853",
  "389",
  "261",
  "265",
  "60",
  "960",
  "223",
  "356",
  "692",
  "596",
  "222",
  "230",
  "269",
  "52",
  "691",
  "373",
  "377",
  "976",
  "664",
  "212",
  "258",
  "95",
  "264",
  "674",
  "977",
  "31",
  "599",
  "687",
  "64",
  "505",
  "227",
  "234",
  "683",
  "672",
  "670",
  "47",
  "968",
  "92",
  "680",
  "970",
  "507",
  "675",
  "595",
  "51",
  "63",
  "48",
  "351",
  "787",
  "974",
  "262",
  "40",
  "7",
  "250",
  "290",
  "869",
  "758",
  "508",
  "784",
  "685",
  "378",
  "239",
  "966",
  "221",
  "248",
  "232",
  "65",
  "421",
  "386",
  "677",
  "252",
  "27",
  "34",
  "94",
  "249",
  "597",
  "268",
  "46",
  "41",
  "963",
  "886",
  "992",
  "255",
  "66",
  "690",
  "676",
  "868",
  "216",
  "90",
  "993",
  "649",
  "688",
  "256",
  "380",
  "971",
  "44",
  "1",
  "598",
  "998",
  "678",
  "418",
  "58",
  "84",
  "284",
  "340",
  "681",
  "967",
  "260",
  "263",
];

export const DetailCardsData = [
  {
    imgURL: "/assets/img/main/dubai.png",
    city: "Dubai",
    price: "$2,499",
    from: "UNITED ARAB EMIRATES",
  },
  {
    imgURL: "/assets/img/main/sydney.png",
    city: "Sydney",
    price: "$2,997",
    from: "AUSTRALIA",
  },
  {
    imgURL: "/assets/img/main/tokyo.png",
    city: "Tokyo",
    price: "$2,479",
    from: "JAPAN",
  },
  {
    imgURL: "/assets/img/main/london.png",
    city: "London",
    price: "$2,322",
    from: "ENGLAND",
  },
  {
    imgURL: "/assets/img/main/casablanca.png",
    city: "Casablanca",
    price: "$2,545",
    from: "MOROCCO",
  },
  {
    imgURL: "/assets/img/main/barcelona.png",
    city: "Barcelona",
    price: "$2,244",
    from: "SPAIN",
  },
  {
    imgURL: "/assets/img/main/rome.png",
    city: "Rome",
    price: "$2,178",
    from: "ITALY",
  },
];

export const QuickLandingData = {
  france: {
    title: "air france",
    summary: `<b>Air France</b>, stylized as AIRFRANCE, is the flag carrier of France headquartered in Tremblay-en-France. It is a subsidiary of the Air France–KLM Group. It is a founding member
    of the SkyTeam global airline alliance and has a network that spans five continents. Its cabins are a popular redemption for members of partner programs like Delta SkyMiles.`,
    first: {
      content: [
        `The new first-class product is currently in the design phase and this confirms the carrier’s commitment to maintaining first class.`,
        `As it’s described, this will be “the longest cabin” on the market, and there will be a seat, a sofa, and a full flatbed.`,
        `<b>Air France</b> first class is currently available on select Boeing 777-300ERs. The current first class product, which was introduced back in 2014, features curtains that offer full privacy.`,
        `It’s a phenomenal cabin, and personally I rank <b>Air France</b> as having the second best first class experience in the air, and the best first class experience on the ground.`,
        `<b>La Première - Intercontinental flights:</b>`,
      ],
      list: [
        `A customized service`,
        `Simplified boarding`,
        `A full team at your service`,
        `Delectable cuisine`,
        `A space devoted to your well-being`,
        `Service on board`,
        `Your own personalized living space`,
        `Great entertainment, at your fingertips`,
        `Your seat, your bed`,
      ],
    },
    business: {
      content: [
        `The French carrier is currently updating its business class cabins to feature a 1-2-1 layout.`,
        `The newest designs for the Boeing 777-300ER include more modern finishes and a sliding door on each seat to add privacy. There are still a few Boeing 777/777-300 planes that have a 2-3-2 layout with middle seats in business class, so you’ll want to avoid these.`,
        `No matter which plane you fly, the typically French experience of fine dining and fashionable presentation remain highlights of flying <b>Air France</b> business class. And you can experience it by paying in cash or redeeming miles.`,
        `<b>Air France</b> likes to highlight its “Frenchness:” fine dining with chef partnerships, Champagne, tasty French cheeses and amenity kits packed with Clarins products.`,
        `On top of the French-inspired touches, <br />
         <b>Air France</b> offers many of the perks you might expect from business class.`,
        `<b>Here are some of the highlights:</b>`,
      ],
      list: [
        `Extra Bag Allowance`,
        `Priority Boarding`,
        `Flatbed Seats`,
        `Fine Dining`,
        `Business Class Lounge access`,
      ],
    },
    economy: {
      content: [
        `Premium Economy is sold on every <b>Air France</b> flight that is operated by wide-body aircraft, including the Boeing 777, Boeing 787, Airbus A330, and Airbus A350.`,
        `Premium economy products among carriers can range from being more like the economy class to being much closer to business class. The <b>Air France</b> Premium Economy products is closer to economy class than it is to business—competitors on the same routes offer more spacious seating and more upgraded meals.`,
        `<b>Air France</b> is one of the few carriers advertises the ability to purchase lounge access (subject to availability) for Premium Economy passengers. Premium Economy passengers also enjoy Sky Priority services like expedited check-in, boarding, and baggage delivery.`,
      ],
    },
  },

  british: {
    title: "british airways",
    summary: `<b>British Airways</b>is the flag carrier airline of the United Kingdom. It is headquartered in London, England, near its main hub at Heathrow Airport. It operates one of the largest and the most modern fleets of any airline in the world. It is also the second largest UK based carrier, based on fleet size and passengers carried, behind easyJet.`,
    first: {
      content: [
        `<b>British Airways</b> First Class is the most luxurious way to experience flight. From your own stylish suite, to exclusive access to lounges, fast-track security, and excellent service, it’s the finest way to travel.`,
        `Cross the world’s skies comfortably, calm, and entertained in your own spacious suite. It’s where British heritage meets contemporary design; letting you work in peace, watch a film, or stretch out and fall asleep.`,
      ],
      list: [
        `Fine dining and delicious treats`,
        `A specially designed cabin on 787-9 Dreamliner aircraft`,
        `Free Wi-Fi now available`,
        `Space to prepare and relax`,
        `The Concorde Room – the most luxurious lounge`,
        `The First Wing – express boarding`,
        `Doorstep baggage pick-up & check-in`,
        `Your seat, your bed`,
      ],
    },
    business: {
      content: [
        `<b>British Airways</b> has several classes of travel designed for the comfort of business travelers.`,
        `Travel within countries like the USA or short flights inside Europe, are generally not as luxurious as long-haul international flights.`,
        `On international flights, however, the new club suites in the brand new business class Airbus A350 -1000 might just set a new industry standard.`,
        `<b>British Airways Club World Suites:</b>`,
      ],
      list: [
        `Lie-flat seat position`,
        `Dedicated business class cabin`,
        `40% more personal storage space`,
        `A vanity area with a mirror`,
        `Wi-Fi and PC/USB power`,
        `High-definition 18.5in screen with entertainment options`,
        `Luxury bedding`,
        `Dedicated support team for all your travel needs`,
        `Restore & Relax Spa collections amenity kit`,
        `Gourmet menu options`,
      ],
    },
    economy: {
      content: [
        `Enjoy an enhanced in-flight experience with World Traveller Plus.`,
        `Flying World Traveller Plus is an exciting upgrade.`,
        `With fewer rows, the cabin is quieter, more spacious, and more exclusive.`,
      ],
      list: [
        "Wider seats and more legroom",
        "A separate, smaller cabin with attentive service",
        "Two delicious meals, complimentary bar service including signature cocktails (or mocktail)",
        "A personal entertainment system with noise-cancelling headphones",
        "Stylish amenity kit made from recycled material",
        "More free baggage allowance",
        "Priority boarding",
      ],
    },
  },

  lufthansa: {
    title: "Lufthansa",
    // summary: `<b>British Airways</b>is the flag carrier airline of the United Kingdom. It is headquartered in London, England, near its main hub at Heathrow Airport. It operates one of the largest and the most modern fleets of any airline in the world. It is also the second largest UK based carrier, based on fleet size and passengers carried, behind easyJet.`,
    first: {
      content: [
        `In <b>Lufthansa</b>’s First Class cabin, you’ll get your own suite with a considerable degree of privacy, a 17” in-flight entertainment monitor, a dazzling selection of expensive wines and spirits, a consistently excellent onboard gastronomic experience including caviar, and one of the most comfortable beds in the sky.`,
        `In addition, if you are departing or connecting in Frankfurt Airport, you have the opportunity to make use of the standalone <b>Lufthansa First Class Terminal</b>, where you can enjoy a relaxing bath with champagne on ice, a restaurant-quality dine-on-demand menu, and a well-stocked bar.`,
        `Furthermore, you’ll be personally driven to your departing flight in either a Porsche or a Mercedes-Benz, casting aside the usual grievances associated with air travel.`,
      ],
      list: [
        `Valet parking service`,
        `Personal assistant`,
        `First Class Terminal`,
        `First Class Lounges`,
        `Limousine service`,
        `Comfort on board`,
        `Culinary delights & fine wines`,
        `Entertainment world`,
      ],
    },
    business: {
      content: [
        `<b>Lufthansa</b> offers a business class product on most flights. As with many European carriers, the differences between intra-Europe Business and long haul Business are substantial – this review primarily addresses the intercontinental long haul business class experience.`,
        `It’s notable that <b>Lufthansa</b> announced upgrades to the business class seating product several years ago, but the pandemic and production delays for some of the newer aircraft planned to join the fleet mean that the existing business class product will be the standard for a few more years to come.`,
        `Passengers who prefer an open plan business class cabin instead of one with highly engineered seats with lots of plastic dividers will appreciate Lufthansa’s spacious, cushy seating.`,
      ],
      list: [
        `More baggage`,
        `More personal space`,
        `Finest dining pleasure`,
        `On-board entertainment`,
        `Relax before your departure`,
        `Lufthansa Business Lounges`,
        `Priority check-in and deplane`,
      ],
    },
    economy: {
      content: [
        `More space, more free baggage, more service – long-haul flights in Premium Economy Class offer plenty of comforts.`,
        `Consequently, you’ll enjoy a relaxed flight to your destination and will be able to head off for some sightseeing or start your meeting as soon as you arrive.`,
      ],
      list: [
        "Up to 50% more personal space",
        "Two items of free baggage",
        "Welcome drink",
        "Practical travel kit",
        "Entertainment",
      ],
    },
  },

  american: {
    title: "american airlines",
    // summary: `<b>British Airways</b>is the flag carrier airline of the United Kingdom. It is headquartered in London, England, near its main hub at Heathrow Airport. It operates one of the largest and the most modern fleets of any airline in the world. It is also the second largest UK based carrier, based on fleet size and passengers carried, behind easyJet.`,
    first: {
      content: [
        `<b>American Airlines</b> First Class ticket gives you access to our most exclusive, personalized amenities and services on international flights between the U.S. and Asia, Australia, Europe and South America.`,
        `You can experience private check-in with access to an expedited security line. Available in select cities. Relax before you fly in premium lounges in select hub cities or access International First Class Lounges and Admirals Club lounges in other cities. Ultimate premium assistance to your departure, connection or arrival in select cities. Enjoy an exclusive fine-dining experience inside select lounges allowing you to eat before your flight and sleep sooner. Speed through security and boarding at the airport and get your checked bags first when you land. In flight comfort covered with pajamas, slippers, mattress pads, pillows and blankets created alongside sleep technology company Casper.`,
      ],
      list: [
        `Expedited Check-In`,
        `Flagship Lounge`,
        `Arrivals Lounge`,
        `First Class Dining`,
        `Priority boarding and security`,
        `Lie-flat seats`,
        `Chef-inspired dining`,
        `Free entertainment`,
        `Wi-Fi and power`,
      ],
    },
    business: {
      content: [
        `A premium international travel experience where a Business Class ticket gets you the highest level of service on shorter international flights between the U.S. and Bermuda, Canada, the Caribbean, Mexico, Puerto Rico and select South American cities.`,
        `Ultimate premium assistance to your departure, connection or arrival in select markets. On qualifying flights, relax before you fly in premium lounges in select hub cities or access the Admirals Club lounges in other cities. Relax with wider seats and more legroom in the Business cabin. For your meals, choose from a larger menu in Business. Plus, you can pair your meal with award-winning wines. All inflight entertainment is free, with seatback and wireless streaming available on select aircraft. Never miss a beat while you’re on board with Wi-Fi on select aircraft.`,
      ],
      list: [
        `Priority boarding and deplane`,
        `Flagship Lounge`,
        `Wider seats with more legroom`,
        `Premium dining`,
        `Entertainment`,
        `Wi-Fi`,
        `Loyalty benefits`,
      ],
    },
    economy: {
      content: [
        `A Premium Economy ticket includes special amenities with seats behind Business or First. Speed through check-in, security and boarding at the airport and get your checked bags first when you land. More legroom and wider, adjustable leather seats with extendable foot and head rests. Chef-inspired meal with complimentary beer, wine and spirits. Free, personal on-demand entertainment with larger monitors and noise-reducing headphones. Never miss a beat while you’re on board with Wi-Fi. Plus, every seat includes power outlets and USB ports. Comfort covered with a pillow and blanket created alongside sleep technology company Casper.`,
      ],
      list: [
        "Priority privileges",
        "Wider seats",
        "Chef-inspired dining",
        "Free entertainment",
        "Wi-Fi and power",
        "Amenities",
        "Casper sleep set",
      ],
    },
  },

  canada: {
    title: "air canada",
    // summary: `<b>British Airways</b>is the flag carrier airline of the United Kingdom. It is headquartered in London, England, near its main hub at Heathrow Airport. It operates one of the largest and the most modern fleets of any airline in the world. It is also the second largest UK based carrier, based on fleet size and passengers carried, behind easyJet.`,
    first: {
      content: [
        `<b>Air Canada</b> Signature Service is the end-to-end premium travel experience featuring the Air Canada Signature Class cabin, formerly known as International Business Class. It is offered exclusively when flying to and from Africa, Asia, Australia, Europe and South America.`,
        `<b>Air Canada</b> Signature Service ensures a seamless airport experience with dedicated check-in counters, expedited security clearance, lounge access, exclusive boarding lanes, priority baggage handling and more. On board, Air Canada Signature Class customers are treated to exclusive service and amenities.`,
        `When you depart from Toronto Pearson International Airport on a domestic or international flight in Air Canada Signature Class, you’ll enjoy a full-service check-in space featuring comfortable seating and dedicated self-service check-in kiosks. Dedicated Priority Check-In counters for accelerated services are available at all airports. Air Canada Signature Class passengers departing from a Canadian airport receive personalized check-in service from our team of specially trained Premium Agents. Board first or at your leisure with dedicated Zone 1 lanes available at most gates. Fast-track through immigration* with our dedicated lanes at select airports. Your bags will be marked with priority tags so they’re first on the carousel when you arrive at your destination. Industry-leading concierges are there to help with check-in assistance inside our exclusive concierge offices at our Toronto, Montreal and Vancouver airports. Maple Leaf Lounges are located in 16 airports across Canada, the United States and Europe. They feature complimentary Wi-Fi, a selection of digital and print newspapers and magazines, complimentary food and beverage options including newly created dishes by Chef David Hawksworth served at live cooking stations.`,
      ],
      list: [
        `Business Class Check-In`,
        `Priority Check-In`,
        `Express security`,
        `Priority boarding and deplane`,
        `Immigration and baggage handling`,
        `Concierge Service`,
        `The Air Canada Signature Suites`,
        `Maple Leaf Lounges`,
        `Air Canada Chauffeur Service`,
        `Food & Beverage Service`,
        `In-Flight Entertainment`,
      ],
    },
  },

  united: {
    title: "united airlines",
    first: {
      content: [
        `When you choose United First, the leisure of premium cabin travel will begin before you even board the aircraft. <br /><br />
        Enjoy a smooth start to your journey with premium travel services at the airport, including designated Premier Access check-in counters and security lanes and priority boarding. Experience the comfort and service of United First. <br /><br />
        Available on flights throughout the U.S., including Alaska, Hawaii and Canada. From better food to bigger seats, United First entails extras that will make your flight a lot more comfortable.
        `,
      ],
      list: [
        `United Airlines Premier Access`,
        `United First class baggage`,
        `United Priority boarding`,
        `United First class seats`,
        `United First class food and drinks`,
        `Rescheduling your United First class flight`,
        `Booking United First class with points and miles`,
      ],
    },
    business: {
      content: [
        `You deserve to travel in style and luxury. United Business has got you covered when flying between the U.S. and Europe and anywhere else in the world.`,
        `When traveling internationally from the U.S., United Business and United Polaris offer the most premium and luxurious experience. United Airlines has phased out its First Class for international flights, but United First® is still available for flights throughout the U.S., including Alaska, Hawaii, and Canada.`,
        `If you want to enjoy perks and discounts, priority check-ins and baggage arrivals, extra baggage allowance, lounge access, and world-class in-flight entertainment, then consider United Business and United Polaris.`,
      ],
      list: [
        `More baggage`,
        `Premier Access`,
        `Premium Plus Seats`,
        `Lounges`,
        `Entertainment`,
        `Food and beverages`,
      ],
    },
  },

  iberia: {
    title: "iberia airlines",
    first: {
      content: [
        `While <b>Iberia Airlines</b> does not have a first class, it does offer what it calls Business Class Plus. You can use all Iberia and One World alliance partner VIP lounges before boarding your flight by choosing to fly Business Class Plus. Passengers at Iberia's Madrid Terminal 4 hub can use two lounges, the Iberia Velázquez Premium Lounge and the Iberia Dalí Premium Lounge.`,
        `All business class plus seats on Iberia long-haul aircraft have direct aisle access and can be made into a fully flat 76-inch bed. The 23 inch wide seats come with a duvet, pillow, amenity kit, noise-canceling headphones, and a video screen feature the latest Hollywood releases, the best of Spanish cinema, and a wide variety of television shows. All seats come with individual lighting, universal power points, and a USB connection.`,
      ],
      list: [
        `More baggage`,
        `Priority business class check-in and boarding`,
        `Flat Bed Seats`,
        `Lounges`,
        `Iberia business class meals`,
        `Free travel advisors`,
        `Entertainment`,
      ],
    },
    business: {
      content: [
        `More and more airlines are incorporating "premium economy" seats on long-haul aircraft. Iberia is no different with 24 recliner seats premium economy seats. <br /><br />
        The seats are laid out differently from standard economy with a 2-4-2 configuration to provide 37 inches of separation between rows. <br />
        All premium economy seats come with a 12-inch screen, noise-canceling headphones, a pillow, and a blanket.
        `,
      ],
      list: [
        `Wider seats`,
        `Food & Drinks`,
        `Entertainment`,
        `More personalized service from crew`,
        `Wi-Fi`,
      ],
    },
    economy: {
      content: [
        `On Iberia Airbus A350s, you will find 293 seats with 18-inch width and a 31-inch recline. <br />
        Standard economy seats on Iberia A330-300 and A330-200 aircraft offer the same width and recline. <br /><br />
        All passengers flying standard economy are supplied with a pillow and blanket. <br />
        Entertainment is provided by a 10-inch screen in the seat in front, and there are charging points built into the seat for your mobile devices.
        `,
      ],
    },
  },

  delta: {
    title: "delta airlines",
    first: {
      content: [
        `<b>Delta One</b> is the most premium product, available (mostly) on long-haul international flights. <br /> It offers lie-flat seats and premium food and beverage service. It also operates on a limited scale between select domestic cities. Since it is available on Delta’s longest flights, it is more lavish in its scale and amenities.`,
        `At the airport <b>Delta One</b> customers are treated to the same amenities as domestic first class passengers with the addition of enjoying access to Sky Clubs on their day of departure. Unlike American and United, Delta does not offer dedicated lounges for its international premium cabin passengers.`,
      ],
      list: [
        `More baggage`,
        `Lie-flat seats`,
        `More personal space`,
        `Food and beverages`,
        `Priority boarding and deplane`,
      ],
    },
    business: {
      content: [
        `Delta Air Lines is arguably the best of the three major airlines in the USA - offering excellent service in all classes of service including its top cabin, Delta One. <br />
        With flat-bed seats, celebrity chef partnerships for meals served on designer Alessi dishes and Tumi amenity kits, the airline chooses to partner with high-end brands that match its own ethos.
        `,
        `Delta offers a First Class and a Delta One fare, albeit never for the same flight. Confusingly, Delta One is the airline’s premium offering – and is available mostly on its long-haul international flights. First Class is available on domestic/shorter flights and invariably does not provide a lie-flat seat.`,
        `The Delta coast-to-coast network in the United States is unrivalled, and its international route map includes six continents, all of which are flown with the Delta One product. The airline invests in the customer experience, and its fliers are fiercely loyal to the airline and its associated SkyMiles program.`,
      ],
      list: [
        `Reclining seats`,
        `Adjustable footrest`,
        `Larger screen`,
        `Accelerated check-in`,
        `Amenity kit`,
        `Meals & Drinks`,
      ],
    },
    economy: {
      content: [
        `Atlanta-based Delta Air Lines is the second-largest airline in the world by number of flights. From basic economy to domestic first class and international business class, it offers all kinds of service to all kinds of travelers.`,
        `Whether you need to fly domestically within the U.S. or cross an ocean for a farther destination, you want to do it in comfort. Perhaps you prefer something a little better than economy but less fancy than business class, especially on long-haul flights. Delta’s Comfort Plus, the airline’s premium economy cabin level, might offer just what you're looking for.`,
        `Here’s an overview of what you get when flying domestically and internationally in Delta Comfort Plus, including your baggage allowance, seat pitch and in-flight service.`,
      ],
      list: [
        `Bag allowance`,
        `Early boarding and deplane`,
        `Premium food`,
        `More legroom`,
        `Dedicated overhead bin space`,
      ],
    },
  },

  virgin: {
    title: "Virgin Atlantic",
    // summary: `<b>British Airways</b>is the flag carrier airline of the United Kingdom. It is headquartered in London, England, near its main hub at Heathrow Airport. It operates one of the largest and the most modern fleets of any airline in the world. It is also the second largest UK based carrier, based on fleet size and passengers carried, behind easyJet.`,
    first: {
      content: [
        `When flying <b>Virgin Atlantic</b> first class, also known as upper class, you'll experience luxury every step of the way. Your experience starts with a chauffeured ride to the airport, and then continues with exclusive airport lounges — and that's all before your eventual departure.`,
        `Travelers looking for a luxurious experience should consider booking a first class flight on Virgin Atlantic. They'll enjoy premium service from the moment they step into the chauffeured airport ride until takeoff, then can relax in lie-flat seats and onboard social areas.`,
      ],
      list: [
        `More baggage`,
        `Priority boarding and deplane`,
        `Lie flat seats`,
        `Onboard social areas`,
        `First Class Lounges`,
        `Food and drink service`,
        `Chauffer service to and from airport`,
      ],
    },
    business: {
      content: [
        `The <b>airline’s Business Class cabin</b> boasts a brand new seat, evolution of its iconic social space (The Loft) and the unveiling of The Retreat Suite, the most spacious suite in Virgin Atlantic’s history which feels more like First Class than Business Class.`,
        `The Retreat Suite is a brand new innovation in <b>Virgin Atlantic’s Business</b> Class offering and consists of two exclusive suites at the front of the cabin. The airline’s most spacious suite yet, comprising a 6ft7” direct seat to a fully flat bed and a 27” touchscreen with Bluetooth connectivity and wireless charging. With each suite boasting an ottoman that doubles up as an extra seat, up to four people can comfortably dine, chat or play games in their own private social space.  Customers can enjoy an intimate dinner or settle in comfort to watch a film.`,
        `The airline’s award-winning social space, The Loft, remains a focal point of the cabin design as customers board the aircraft and is a core part of the Upper Class experience. In an evolution of design, it consists of space for eight people, with four comfortable seats, allowing guests to sit and chat or enjoy refreshments from the new self-service fridge and drinks dispenser.  `,
      ],
      list: [
        `More baggage`,
        `Priority boarding and deplane`,
        `Lie flat seats`,
        `Onboard social areas`,
        `First Class Lounges`,
        `Food and drink service`,
        `Chauffer service to and from airport`,
      ],
    },
    economy: {
      content: [
        `Stylish touches make big differences in Premium Economy cabin.  Virgin Atlantic’s trademark burgundy Premium seats, with a 38” pitch remain, but they’re accompanied by calf rests in every seat and a 13.3” touchscreen with Bluetooth audio.  The airline is also offering its Premium customers an in-seat wireless charging pad.`,
      ],
      list: [
        "Skip the lines",
        "Premium comfort",
        "Stay connected",
        "Delicious dining",
        "Entertainment",
        "More baggage",
      ],
    },
  },

  jetblue: {
    title: "Jetblue Airways",
    summary: `<b>JetBlue Airways</b> (stylized as JetBlue) is a major American low-cost airline and also the seventh-largest airline in North America by passengers carried.
    <b>JetBlue Airways</b> is headquartered within the Long Island City neighborhood of the big apple City borough of Queens; it also maintains corporate offices in Utah and Florida.`,
    first: {
      content: [
        `<b>JetBlue</b> - Vertical Fleet provides the most effective deals for brand spanking new bookings up to 30% off  domestic flights.<br /><br />
        When making changes with <b>JetBlue</b> airline flights, we offer a waiver so you do not have to pay higher fee on change fees and fare differences.<br /><br />
        When making cancellations with <b>JetBlue</b> Airlines we offer a waiver in order that most of the time you get a full refund and credit on a domestic flight.
        `,
        `<b>JetBlue</b> offers Core (Main Cabin) seating across its fleet. The Core cabin includes leather seats, complimentary Wi-Fi, complimentary entertainment screens with DirecTV, Sirius XM Radio, and movies, still as complimentary snacks and non-alcoholic drinks.`,
      ],
      list: [],
    },
    business: {
      content: [
        `Mint is JetBlue's business class product and was originally available only on transcontinental domestic routes. <br />
        It includes fully lie-flat seats, a number of which have sliding panels for more privacy.<br /><br />
        Mint was expanded to pick out Caribbean routes, and in 2021, an upgraded version of the service and seating was announced. In-flight entertainment. <br /><br />
        The in-flight Wi-Fi under the "Fly-Fi" network is complimentary on all flights, at speeds of 12–15 megabits per second.
        `,
      ],
      list: [],
    },
    economy: {
      content: [
        `JetBlue's frequent-flyer program is termed True-blue. <br /><br />
        Under the initial True-blue program, flights worth two, four, or six points supported the distance of the flights and double points.`,
      ],
      list: [],
    },
  },

  alaska: {
    title: "Alaska Airlines",
    summary: `<b>Alaska Airlines</b> is a major American airline headquartered in SeaTac, Washington, within the Seattle metropolitan area. it's the sixth-largest when measured by fleet size, scheduled passengers carried and also the number of destinations served. Alaska, along with its regional partners Horizon Air and SkyWest Airlines, operates an outsized domestic route network, primarily focused on connecting from the geographic region, West Coast and Alaska to over 100 destinations within the contiguous US, Hawaii, Belize, Canada, Costa Rica, and Mexico.`,
    first: {
      content: [
        `<b>Alaska Airline</b> - Vertical Fleet provides the most effective deals for brand spanking new bookings up to twenty percent off market fares on flights. <br /><br />
        When making changes with <b>Alaska Airline</b> flights are now much easier, we offer a waiver so you don’t pay higher change fees and fare differences.<br />
        When cancelling with <b>Alaska Airline</b> we offer a waiver so most of the time you get a full refund and credit on a flight.
        `,
      ],
      list: [],
    },
    second: {
      content: [
        `First Class is available on all mainline aircraft and jet regional aircraft. <br />
        First-class features priority boarding and complimentary hot meals, alcoholic or non-alcoholic beverages, towel service, and snacks. <br />
        Seating is during a 2-2 configuration featuring recliner-style seats.
        `,
      ],
      list: [],
    },
    business: {
      content: [
        `Premium Class is accessible on all mainline aircraft and every one jet regional aircraft. <br />
        It's located within the first few rows of the economy cabin and features 35" of seat pitch four more inches than in Alaska's Main Cabin. <br /><br />
        Passengers receive priority boarding, complimentary alcoholic or non-alcoholic beverages, together with a complimentary snack. Additional food is purchased.
        `,
      ],
      list: [],
    },
    economy: {
      content: [
        `Main Cabin is Alaska's economy class offering. <br /><br />
        Main Cabin passengers receive a complimentary snack and a non-alcoholic drink. <br />
        Fresh food, alcoholic beverages, and extra snacks are available for purchase. On mainline aircraft, every seat in Main Cabin offers USB and power outlets. <br />
        On turboprop regional aircraft, passengers in main cabin receive a complimentary glass of wine or beer.
        `,
      ],
      list: [],
    },
  },

  southwest: {
    title: "Southwest Airlines",
    summary: `<b>Southwest Airlines</b> typically named as Southwest, is one of the key airlines within the US. It is the world's largest low-cost carrier that's headquartered in Dallas, Texas. Southwest has scheduled service to 121 destinations within the US and 10 additional countries. As of 2018, Southwest carried more domestic passengers than any other US airline.`,
    first: {
      content: [
        `<b>Southwest Airline</b> - Vertical Fleet provides the most effective deals for brand-new bookings up to 20% off on market fares for domestic and flight. <br /><br />
        When making changes with Southwest airline flights, we offer a waiver so you do not have to pay higher fee on change fees and fare differences. <br />
        Cancellation with southwest we offer a waiver in order that most of the time you get a full refund and credit on a domestic flight.
        `,
      ],
      list: [],
    },
    second: {
      content: [
        `As of 2021, Southwest scheduled flights to over 100 destinations in 42 states, Puerto Rico, Mexico, Central America and also the Caribbean. <br />
        Southwest doesn't use the standard hub and spoke system of other major airlines, preferring a point-to-point system combined with a rolling hub model in its base cities. <br /><br />
        `,
      ],
      list: [],
    },
    business: {
      content: [
        `Southwest first began to supply a frequent-flyer program on June 18, 1987, calling it the corporate Club. <br />
        The program is credited for trips flown no matter distance. Southwest Airlines renamed its frequent flyer program Rapid Rewards.
        `,
      ],
      list: [],
    },
    economy: {
      content: [
        `Southwest permits two free-of-charge checked bags per passenger, and passengers are permitted to alter their flight up to 10 minutes before their flights without extra charge. <br />
        within the event of a cancellation, passengers are refunded a travel credit within the amount spent on their ticket, and therefore the credit is also used toward other Southwest Airlines or Southwest Vacations purchases within a year of the first ticket purchase. <br /><br />
        Southwest offers free in-flight non-alcoholic beverages and offers alcoholic beverages purchasable for $6 to $7 per beverage. Free alcoholic drinks are offered on some holidays like Valentine Day and Halloween. <br /> They even have complimentary snacks on all flights.
        `,
      ],
      list: [],
    },
  },

  sunCountry: {
    title: "Sun Country Airlines",
    summary: `<b>Sun Country Airlines</b> is a budget-friendly American airline that focuses on providing affordable travel options for passengers and cargo. It ranks as the eleventh largest airline in the United States in terms of the number of passengers carried.`,
    first: {
      content: [
        `<b>Sun Country Airlines</b> - Vertical Fleet offers highly competitive deals on new bookings, with discounts of up to 20% off standard fares for domestic flights. When modifying your flight arrangements with Sun Country Airlines, we provide a waiver that eliminates the need to pay excessive change fees and fare differences. Additionally, cancellations with Sun Country Airlines often result in a full refund or credit for domestic flights.`,
      ],
      list: [],
    },
    second: {
      content: [
        `<b>Sun Country Airlines</b> ensures safe, dependable, and hassle-free flights at affordable rates, coupled with warm and friendly service. The airline's mission is to connect travelers with their desired destinations and loved ones, creating everlasting memories and transformative experiences. <br />
      Sun Country serves various locations, including Anchorage, Atlantic City, Baltimore, Boston, Bozeman, Charleston, Chicago, Las Vegas, Los Angeles, New Orleans, New York, San Francisco, Savannah, Seattle, and continuously adds more routes to its expanding network.
      `,
      ],
      list: [],
    },
    business: {
      content: [
        `<b>Sun Country Rewards</b> is a loyalty program that simplifies the process of earning points, which can be redeemed for travel anytime and without any blackout dates.`,
      ],
      list: [],
    },
    economy: {
      content: [
        `When you choose to fly with Sun Country Airlines, you'll enjoy exceptional value, exceptional customer service, and outstanding amenities. These include comfortable seating and complimentary non-alcoholic beverages.`,
      ],
      list: [],
    },
  },

  aeroMexico: {
    title: "Aero Mexico Airlines",
    summary: `<b>Aero Mexico Airlines</b> is a leading Mexican airline offering reliable and comfortable air travel with a commitment to exceptional service. With a wide range of domestic and international destinations, Aero Mexico ensures a seamless and enjoyable flying experience for passengers.`,
    first: {
      content: [
        `<b>Aero Mexico Airlines</b> offers enticing deals and promotions to make your travel experience even more affordable and enjoyable. <br />
        Take advantage of special discounts on selected flights, enjoy exclusive offers on vacation packages, and earn rewards through their loyalty program. Fly with Aero Mexico and discover a world of great value and exceptional service.
        `,
      ],
      list: [],
    },
    second: {
      content: [
        `<b>Aero Mexico Airlines</b> takes you on a journey to a diverse range of captivating destinations around the world. Whether you're seeking the vibrant cultural scene of Mexico City, the picturesque beaches of Cancun, the ancient ruins of Chichen Itza, or the colonial charm of Oaxaca, Aero Mexico Airlines has you covered. <br />
      Explore their extensive network of domestic and international routes, including popular destinations in North America, Central America, South America, Europe, and Asia. With Aero Mexico, you can experience seamless connections, exceptional service, and the opportunity to create unforgettable memories in some of the most enchanting places on Earth.
      `,
      ],
      list: [],
    },
    business: {
      content: [
        `<b>Aero Mexico Rewards</b> is a loyalty program that offers members the opportunity to earn points and enjoy exclusive benefits. Earn points when you fly with Aero Mexico and its partner airlines, and redeem them for free flights, upgrades, lounge access, and more. Join Aero Mexico Rewards to make your travel experience even more rewarding.`,
      ],
      list: [],
    },
    economy: {
      content: [
        `<b>Aero Mexico Airlines</b> provides a premium onboard experience that combines comfort, exceptional service, and modern amenities. Relax in spacious seating, savor delicious cuisine, and enjoy a wide range of entertainment options. With attentive cabin crew and a commitment to passenger satisfaction, Aero Mexico ensures a pleasant and enjoyable journey from takeoff to landing.`,
      ],
      list: [],
    },
  },

  frontier: {
    title: "Frontier Airlines",
    summary: `<b>Frontier Airlines</b> is a low-cost American carrier that offers affordable and convenient air travel options. With a focus on budget-friendly fares, Frontier Airlines provides an extensive network of domestic and select international destinations. Passengers can expect a no-frills experience with the option to customize their travel by choosing additional services as needed. Frontier Airlines is dedicated to delivering reliable service and making air travel accessible to a wide range of travelers.`,
    first: {
      content: [
        `<b>Frontier Airlines</b> presents a range of enticing offers to make your travel experience even more affordable. Take advantage of discounted fares, special promotions, and exclusive deals on selected flights. <br />
        With Frontier Airlines, you can find great value for your money while enjoying reliable service and convenient air travel options. Discover the latest offers and embark on your next adventure with Frontier Airlines.
        `,
      ],
      list: [],
    },
    second: {
      content: [
        `<b>Frontier Airlines</b> connects travelers to a variety of exciting destinations across the United States and select international locations. <br />
      Whether you're seeking the beautiful beaches of Florida, the bustling cities of New York and Los Angeles, or the natural wonders of national parks, Frontier Airlines has a diverse range of destinations to explore. </br>
      With a growing network, Frontier Airlines allows you to discover new places, create memorable experiences, and embark on exciting adventures. </br>
      `,
      ],
      list: [],
    },
    business: {
      content: [
        `<b>Frontier Airlines</b> loyalty program, Frontier Miles, rewards frequent flyers with a variety of exclusive benefits. Earn miles on eligible flights, hotel stays, car rentals, and more. Redeem your miles for free flights, seat upgrades, and other travel perks. </br>
        As a Frontier Miles member, you'll enjoy a personalized travel experience with access to priority services and special offers. Join Frontier Miles and start earning rewards for your loyalty with Frontier Airlines.
        `,
      ],
      list: [],
    },
    economy: {
      content: [
        `<b>Frontier Airlines</b> provides a comfortable and affordable onboard experience. Passengers can expect a no-frills approach with the option to customize their journey through add-ons and services. Enjoy spacious seating, friendly service, and a selection of snacks and beverages available for purchase. </br>
        Frontier Airlines strives to make your flight enjoyable and convenient, ensuring a smooth travel experience at a budget-friendly price.
        `,
      ],
      list: [],
    },
  },

  evaAir: {
    title: "Eva Air",
    summary: `<b>EVA Air</b> is a prominent international airline based in Taiwan. It is known for its exceptional service and high-quality passenger experience. EVA Air operates a global network of routes, connecting passengers to numerous destinations around the world. The airline is renowned for its comfortable cabins, delicious cuisine, and top-notch in-flight entertainment. EVA Air consistently receives recognition for its commitment to safety, service excellence, and environmental sustainability, making it a preferred choice for travelers seeking a premium flying experience.`,
    first: {
      content: [
        `<b>EVA Air's First Class</b> is a pinnacle of luxury in air travel.`,
        `Enjoy spacious private suites, gourmet dining, and a dedicated cabin crew. Seats that convert into fully flat beds, elegant loungewear, and premium amenities provide an indulgent and memorable journey.`,
      ],
      list: [
        `Spacious Private Suites`,
        `Gourmet Dining`,
        `Personalized Service`,
        `Fully Flat Beds`,
        `Elegant Loungewear`,
        `Top-tier Amenities`,
      ],
    },
    business: {
      content: [
        `<b>EVA Air's Business Class</b> offers a sophisticated and comfortable travel experience.`,
        `Passengers can expect spacious seating, gourmet dining, and a range of premium amenities. The seats are designed for relaxation and productivity, and the attentive cabin crew ensures a smooth and enjoyable journey.
         <b>EVA Air's</b> commitment to excellence is evident in every aspect of their Business Class service. </br>
         <b>EVA Air's Business Class</b> offers a sophisticated and comfortable way to travel, where every detail is tailored to elevate your journey so you can travel in style and comfort.
         `,
      ],
      list: [
        `Spacious Seating`,
        `Gourmet Dining`,
        `Premium Amenities`,
        `Attentive Cabin Crew`,
      ],
    },
    economy: {
      content: [
        `<b>EVA Air's Economy Class</b> provides a comfortable and cost-effective way to travel.`,
        `Passengers enjoy well-designed seating, in-flight dining, and a selection of entertainment options. With attentive service from the cabin crew, EVA Air ensures a pleasant journey for all travelers.`
      ],
      list: [
        `Spacious Seating`,
        `In-Flight Dining`,
        `In-Flight Entertainment`,
        `Attentive Service`,
      ]
    },
  },

  emirates: {
    title: "Emirates Airways",
    summary: `<b>Emirates Airways</b>, based in Dubai, United Arab Emirates, is one of the world's leading and most renowned airlines. Known for its luxurious services and a vast global network, Emirates offers passengers an exceptional travel experience. The airline operates a modern fleet of aircraft and is recognized for its premium amenities, in-flight entertainment, and world-class cabin crew. Emirates is a symbol of comfort, innovation, and excellence in the airline industry.`,
    first: {
      content: [
        `<b>Emirates Airways's First Class</b> offers the pinnacle of luxury air travel.`,
        `Passengers can expect spacious private suites, gourmet dining, world-class service, and access to exclusive lounges. With fully flat beds, in-flight showers, and a range of amenities, this experience provides ultimate comfort and opulence for those seeking the finest in air travel.`,
      ],
      list: [
        `Private Suites`,
        `Spacious Comfort`,
        `Gourmet Dining`,
        `Entertainment`,
        `Personal Service`,
        `Lounge Access`,
        `Priority Check-In and Boarding`,
        `Luxurious Amenities`,
        `In-Flight Connectivity`,
        `Priority Baggage Handling`,
      ],
    },
    business: {
      content: [
        `<b>Emirates Airways's Business Class</b> provides a premium and comfortable travel experience designed to cater to the needs of discerning travelers.`,
        `<b>Emirates Airways's Business Class</b> combines comfort, dining, and entertainment to provide a premium travel experience suitable for both leisure and business travelers.`,
      ],
      list: [
        `Spacious Seating`,
        `Dining`,
        `Entertainment`,
        `Amenities`,
        `Lounge Access`,
        `Priority Services`,
        `In-Flight Connectivity`,
        `Comfortable Workspaces`,
        `Fine Service`,
      ],
    },
    economy: {
      content: [
        `<b>Emirates Airways's Economy Class</b> provides a comfortable and value-oriented travel experience.`,
        `Emirates Airways' Economy Class offers a quality travel experience at an affordable price, making it a popular choice for travelers seeking comfort and value during their journey.`
      ],
      list: [
        `Comfortable Seating`,
        `In-Flight Entertainment`,
        `Dining`,
        `Amenities`,
        `In-Flight Connectivity`,
        `Service`,
        `Lounge Access`,
      ]
    },
  },

  tapAirPortugal: {
    title: "TAP Air Portugal",
    summary: `<b>TAP Air Portugal,</b> commonly known as TAP, is Portugal's national airline. It provides a comprehensive network of domestic and international flights, with Lisbon Portela Airport as its primary hub. TAP offers a range of travel classes, including Economy, EconomyXtra, and Business Class, catering to a diverse set of passengers. The airline is known for its modern fleet, in-flight entertainment, and frequent flyer program, making it a popular choice for travelers seeking to explore Portugal and connect to destinations worldwide.`,
    business: {
      content: [
        `<b>TAP Air Portugal's Business Class</b> often referred to as "TAP Executive," offers a premium and comfortable travel experience for passengers seeking added amenities and services.`,
        `<b>TAP Air Portugal's Business Class</b> is designed to offer a premium travel experience, combining comfort, fine dining, and excellent service for passengers flying to various domestic and international destinations.`,
      ],
      list: [
        `Comfortable Seating`,
        `In-Flight Dining`,
        `Priority Services`,
        `Lounge Access`,
        `In-Flight Entertainment`,
        `Amenities`,
        `Service`,
      ],
    },
    economy: {
      content: [
        `<b>TAP Air Portugal's Economy Class</b> offers a comfortable and cost-effective travel option for passengers.`,
        `TAP Portugal's Economy Class is designed to offer a comfortable and affordable travel experience for passengers flying to various destinations. Please keep in mind that specific services and amenities may have evolved since my last update in January 2022, so it's advisable to check with TAP Portugal for the most current details.`
      ],
      list: [
        `Seating`,
        `In-Flight Dining`,
        `In-Flight Entertainment`,
        `Amenities`,
        `Service`,
        `In-Flight Connectivity`,
      ]
    },
  },

  swissAir: {
    title: "Awiss Air Lines",
    summary: `<b>Swiss International Air Lines,</b> commonly known as Swiss Air or simply SWISS, is the national airline of Switzerland and a subsidiary of Lufthansa Group. Swiss Air is renowned for its Swiss quality and hospitality, offering a comfortable and efficient travel experience while proudly representing Switzerland on the global aviation stage. </br>
      Here's a short description of Swiss Air:
    `,
    first: {
      content: [
        `<b>Swiss Air's First Class</b> is the epitome of luxury air travel, offering a premium and exclusive experience.`,
        `<b>Swiss Air's First Class</b> offers the ultimate in luxury, combining exquisite dining, top-notch service, and a serene travel environment, making it one of the most opulent ways to fly.`,
      ],
      list: [
        `Elegant Seating`,
        `Gourmet Dining`,
        `Attentive Service`,
        `Lounge Access`,
        `In-Flight Entertainment`,
        `Amenities`,
        `Priority Services`,
      ],
    },
    business: {
      content: [
        `<b>Swiss Air's Business Class</b>, known as "SWISS Business," provides a premium and comfortable travel experience.`,
        `<b>Swiss Air's Business Class</b> offers a high level of comfort and service, making it an excellent choice for travelers seeking a premium travel experience on both short-haul and long-haul flights.`,
      ],
      list: [
        `Comfortable Seating`,
        `Gourmet Dining`,
        `Attentive Service`,
        `Airport Lounge Access`,
        `In-Flight Entertainment`,
        `Amenities`,
        `Priority Services`,
      ],
    },
    economy: {
      content: [
        `<b>Swiss Air's Economy Class</b>, known as "SWISS Economy," offers a comfortable and cost-effective travel option for passengers.`,
        `Swiss Air's Economy Class is designed to offer a comfortable and affordable travel experience, making it a suitable choice for travelers flying to various destinations.`
      ],
      list: [
        `Seating`,
        `In-Flight Dining`,
        `In-Flight Entertainment`,
        `Service`,
        `Amenities`,
        `In-Flight Connectivity`,
      ],
    },
  },

  qatar: {
    title: "Qatar Airways",
    summary: `<b>Qatar Airways</b> is the flagship carrier of Qatar, renowned for its world-class service and extensive global network. Operating from Hamad International Airport in Doha, the airline boasts a modern and diverse fleet, serving a wide array of international destinations. Qatar Airways offers multiple travel classes, including Economy, Business, and First Class, all characterized by exceptional amenities, luxurious comfort, and premium in-flight entertainment. The airline has received numerous awards and accolades for its commitment to quality and passenger satisfaction and runs the Qatar Airways Privilege Club, rewarding frequent flyers with various travel benefits.`,
    first: {
      content: [
        `<b>Qatar Airways' First Class</b> is the pinnacle of luxury air travel, providing passengers with an exceptional and exclusive experience.`,
        `<b>Qatar Airways' First Class</b> offers the utmost in opulence, combining gourmet dining, top-tier service, and a serene travel environment, making it one of the most luxurious ways to travel.`,
      ],
      list: [
        `Elegant Seating`,
        `Gourmet Dining`,
        `Attentive Service`,
        `Exclusive Lounges`,
        `In-Flight Entertainment`,
        `Luxurious Amenities`,
        `Priority Services`,
      ],
    },
    business: {
      content: [
        `<b>Qatar Airways' Business Class</b>, known as "Qatar Airways Business," provides a premium and luxurious travel experience.`,
        `<b>Qatar Airways' Business Class</b> is designed to offer an exceptional and comfortable travel experience, making it an excellent choice for both long-haul and short-haul flights.`,
      ],
      list: [
        `Spacious Seating`,
        `Gourmet Dining`,
        `Attentive Service`,
        `Airport Lounges`,
        `In-Flight Entertainment`,
        `Luxurious Amenities`,
        `Priority Services`,
      ],
    },
    economy: {
      content: [
        `<b>Qatar Airways' Economy Class</b> offers a comfortable and well-rounded travel experience for passengers.`,
        `Qatar Airways' Economy Class is designed to offer a comfortable and cost-effective travel experience for passengers flying to various domestic and international destinations.`
      ],
      list: [
        `Comfortable Seating`,
        `In-Flight Dining`,
        `In-Flight Entertainment`,
        `Friendly Service`,
        `Amenities`,
        `In-Flight Connectivity`,
      ]
    },
  },
};
